import Cookies from 'js-cookie';
import 'owl.carousel'
import AOS from 'aos';
import { CountUp } from 'countup.js'
// import Rellax from "rellax"

window.Cookies = Cookies;

// JavaScript
$(document).ready( function () {
  isIE();

  $('#mobileMenuBtn').on('click', function () {
    $(this).toggleClass('active');
    $('.header-menu').toggleClass('active');
    $('.header-main').toggleClass('active');
  });

  $('#userBtn, .user-mask, .menu-close').on('click', function () {
    $('#userBtn').toggleClass('active');
    $('.header-user-menu').toggleClass('active');
    $('.user-mask').toggleClass('active');
  });

  $(".header-menu-item.group-link").on("click", function() {
    $(this).toggleClass('is-active');
  });
  $('.header-menu-nav').find('a').on('click', function() {
    $('#mobileMenuBtn').toggleClass('active');
    $('.header-menu').removeClass('active');
    $('.header-main').removeClass('active');
  });


  $(".back-to-top").on('click', function () {
    $('html, body').animate({ scrollTop: 0 }, 300, 'swing');
  });
  var window_width = $(window).width();
  var scroll_change = 5;
  if (window_width > 900) {
    var scroll_change = 130;
  }
  if ($(window).scrollTop() > scroll_change) {
    $('.header').addClass("rwd-header");
    $(".back-to-top").fadeIn();
  } else {
    $('.header').removeClass("rwd-header");
    $(".back-to-top").fadeOut();
  }

  $(window).on('scroll',function(){
    // transparent header
    if ($(window).scrollTop() > scroll_change){
      $('.header').addClass("rwd-header");
      $(".back-to-top").fadeIn();
    }else{
      $('.header').removeClass("rwd-header");
      $(".back-to-top").fadeOut();
    }
  });


  $('.careers-jobs .careers-job').first().find('careers-job-title, .careers-job-wrapper').toggleClass('is-active');
  $('.careers-job-title').on('click', function() {
    $(this).toggleClass('is-active');
    $(this).parent('.careers-job').find('.careers-job-wrapper').toggleClass('is-active');
  });

  $('.home-news-items').owlCarousel({
    center: true,
    items: 1.5,
    loop: true,
    lazyLoad: true,
    margin: 10,
    nav: true,
    navText: '',
    responsive: {
      680: {
        margin: 15,
        items: 3
      },
      1200: {
        margin: 30,
        items: 4
      },
      1660: {
        margin: 30,
        items: 5
      },
      1920: {
        margin: 30,
        items: 6
      }
    }
  });

  // 尚未完成的頁面提示訊息
  $('[data-alert=maintain]').on("click", function() {
    Swal.fire({
      icon: 'info',
      text: '建置中...',
      showCloseButton: true,
    })
  });

  $('.showcase-show-img-owl').find(".owl-carousel").owlCarousel({
    items: 1,
    margin: 0,
    lazyLoad: true,
    dots: true,
    nav: true,
    navText: '',
  });

  // 產品
  var current_cate = window.location.hash;
  var show_cate = current_cate.replace("#", "");
  var all_cate = [];
  $('.product-cate-menu-item').each(function() {
    all_cate.push($(this).attr('data-cate-menu'));
  })

  if (all_cate.indexOf(show_cate) != -1) {
    $('.product-cate-menu .product-cate-menu-item[data-cate-menu=' + show_cate+']').addClass('is-active');
    $('.product-content .product-panel[data-id=' + show_cate + ']').addClass('is-active');
  }else {
    $('.product-cate-menu .product-cate-menu-item').first().addClass('is-active');
    $('.product-content .product-panel').first().addClass('is-active');
  }
  $('.product-cate-menu .product-cate-menu-item').on('click', function() {
    var cateID = $(this).find('a').attr('href');
    var cate = cateID.replace("#","");
    $('.product-cate-menu-item').removeClass('is-active');
    $('.product-panel').removeClass('is-active');
    $(this).addClass('is-active');
    $('.product-panel[data-id=' + cate +']').addClass('is-active');
  })


} );

window.addEventListener('load', (event) => {
  AOS.init();
  $(window).on("resize", function () {
    setTimeout(() => {
      AOS.refresh();
    }, 3000);
  });
  fixedBlock($('.fixed-sidebar'));

  // plan
  function digitalStar() {
    $(".plan-digital-num").each(function (digital) {
      var Id = $(this).attr("id");
      var num = $(this).attr("data-num");
      var digitalShow = new CountUp(Id, num);
      if (!digitalShow.error) {
        digitalShow.start();
      }
    });
  }

  $(window).on('scroll', function () {
    var digitalPlay = $('.plan-digital').hasClass('aos-animate');
    var digitalDone = $('.plan-digital').hasClass('aos-done');
    if (digitalPlay && !digitalDone) {
      digitalStar();
      $('.plan-digital').addClass('aos-done');
    }
  });

  var items = document.querySelectorAll(".logo-shape-item");
  items.forEach(item => {
    item.addEventListener("mouseover", itemMouseOver);
    item.addEventListener("mouseout", itemMouseOut);
  });

  function itemMouseOver(evt) {
    var areaId = $(this).attr('data-area');
   $('#'+areaId).addClass("active");
  }

  function itemMouseOut(evt) {
    var areaId = $(this).attr('data-area');
    $('#' + areaId).removeClass("active");
  }
});

function fixedBlock(elem) {
  if (elem.length > 0) {
    var elem_fixed = elem.offset().top;
    var frame_h = $(".frame").height();
    var block_h = elem.height();
    $(window).on('scroll', function () {
      if ($(window).scrollTop() > elem_fixed) {
        elem.addClass('fixed');
        if ($(window).scrollTop() > (frame_h - block_h)) {
          elem.addClass('fixed-bottom');
        } else {
          elem.removeClass('fixed-bottom');
        }
      } else {
       elem.removeClass('fixed');
      }
    });
  }
};

function isIE() { //ie?
  if (!!window.ActiveXObject || "ActiveXObject" in window) {
    $("body").addClass('isIE');
  } else {
    $("body").removeClass('isIE');
  }
}
