import 'sweetalert2/src/sweetalert2.scss'
import 'public/vocal/style.scss'
import 'public/vocal/main'
import Rails from "@rails/ujs"
import Swal from 'sweetalert2/dist/sweetalert2.js'
import lottie from "lottie-web";
Rails.start();

window.jQuery = $;
window.$ = $;
window.Swal = Swal;
window.lottie = lottie;
